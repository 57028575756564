import { Box, Grid, Hidden, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import {
  ApplicationProps,
  FeeSlabsType,
  StrategyDetailsType,
} from '../../redux-store/types/api-types';
import { containsLiquid, currencyConversion } from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import SourceOfInvestment from './sourceOfInvestment';
import {
  strategyFeesMaster,
  strategyFeeTypeMaster,
  strategyPerformanceFeeTypeMaster,
  USER_ROLES,
} from '../../utils/constant';
import { SubHeading } from '../investors/components';
import { KeyValue } from './contributor';
import { Gridstyles } from '.';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updatedFeeSlabDetails } from '../investors/strategyDetailsWithFeeSlabs';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { numberRegex, numberRegexWithDigit } from '../../utils/regex';
import { applicationTypes } from '../Reports/report-utils';

const styles: any = {
  header: {
    my: 0,
    py: 0,
    border: 0,
    minWidth: 90,
  },
  boxTitle: {
    fontSize: { xs: 11, md: 14 },
    fontWeight: 400,
    color: '#2057A6',
    textAlign: 'left',
    px: { xs: 0.5, md: 1.5 },
    py: { xs: 0.5, md: 1 },
    height: 48,
    display: 'flex',
    alignItems: 'center',
    background: '#EBF2FA',
  },
  boxValues: {
    py: { xs: 0.5, md: 1 },
    px: { xs: 0.5, md: 1.5 },
    background: '#fafafb',
  },
  boxValuesText: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: 400,
    color: '#293139',
    mb: 0.5,
  },
};

const ApplicationDetailComponent = ({
  applicationstrategydetail,
  liquidApplicationFeeSlab,
  applicationfeeslab,
}: {
  applicationstrategydetail: StrategyDetailsType;
  liquidApplicationFeeSlab: any;
  applicationfeeslab: any;
}): JSX.Element | null => {
  const feeSlab = containsLiquid(applicationstrategydetail?.strategyName || '')
    ? liquidApplicationFeeSlab
    : applicationfeeslab;

  if (!feeSlab) {
    return null;
  }

  return (
    <React.Fragment>
      <SubHeading
        sx={{
          letterSpacing: 0.5 + 'px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Typography> {applicationstrategydetail?.strategyName}</Typography>
        {applicationstrategydetail?.clientCode && (
          <Typography> {applicationstrategydetail?.clientCode}</Typography>
        )}
      </SubHeading>
      <Gridstyles>
        <Grid
          item
          container
          pb={5}
          rowSpacing={6}
          columnSpacing={13}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">
          <KeyValue title="Fee Type" description={feeSlab?.feeType || ''} />
          <KeyValue
            title={'Investment Amount (in INR)'}
            description={applicationstrategydetail.investmentAmount}
            amount={
              applicationstrategydetail.investmentAmount
                ? currencyConversion(applicationstrategydetail.investmentAmount)
                : applicationstrategydetail.investmentAmount
            }
          />
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            border: '1px solid #CBCBCB',
            borderRadius: '2px',
            mb: 1,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexGrow: 1,
              flexWrap: 'wrap',
              background: '#fafafb',
            }}>
            <Box sx={{ width: { xs: '50%', lg: '25%' } }}>
              <Typography sx={styles.boxTitle}>Management Fees %</Typography>
              <Box sx={styles.boxValues}>
                <Typography sx={styles.boxValuesText}>{feeSlab?.mgmtFee || ''}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '50%', lg: '25%' } }}>
              <Typography sx={styles.boxTitle}>Operating Expenses %</Typography>
              <Box sx={styles.boxValues}>
                <Typography sx={styles.boxValuesText}>{feeSlab?.optFee || ''}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '100%', lg: '50%' }, background: '#EBF2FA' }}>
              <Typography
                sx={{
                  ...styles.boxTitle,
                  height: 24,
                  py: 0.2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}>
                Exit Load %
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                {feeSlab?.exitload?.map((exit: any, i: number) => {
                  return (
                    <Box key={i} sx={{ width: '25%' }}>
                      <Typography
                        sx={{
                          ...styles.boxTitle,
                          fontsize: 12,
                          fontWeight: 600,
                          height: 24,
                          py: 0.2,
                        }}>
                        {exit.frequency}
                      </Typography>
                      <Box sx={styles.boxValues}>
                        <Typography sx={styles.boxValuesText}>{exit.exitload || ''}</Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            {feeSlab?.hurdlerates && feeSlab?.hurdlerates?.length > 0 && (
              <>
                <Box sx={{ width: '50%' }}>
                  <Typography sx={styles.boxTitle}>Profit sharing % above hurdle rate</Typography>
                  <Box sx={styles.boxValues}>
                    {feeSlab.hurdlerates?.length ? (
                      feeSlab.hurdlerates?.map((rate: any, rateIndex: number) => (
                        <Typography key={rateIndex} sx={styles.boxValuesText}>
                          {rate.profit}
                        </Typography>
                      ))
                    ) : (
                      <Typography sx={styles.boxValuesText}>-</Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: '50%', background: '#EBF2FA' }}>
                  <Typography sx={{ ...styles.boxTitle, height: 24, py: 0.2 }}>
                    Profit Hurdle Rate
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ width: '50%' }}>
                      <Typography
                        sx={{
                          ...styles.boxTitle,
                          fontsize: 12,
                          fontWeight: 600,
                          height: 24,
                          py: 0.2,
                        }}>
                        % from
                      </Typography>
                      <Box sx={styles.boxValues}>
                        {feeSlab.hurdlerates?.length ? (
                          feeSlab.hurdlerates?.map((rate: any, rateIndex: number) => (
                            <Typography key={rateIndex} sx={styles.boxValuesText}>
                              {rate?.to?.match(numberRegexWithDigit)
                                ? rate.from
                                : `${rate.from} & ${rate.to}`}
                            </Typography>
                          ))
                        ) : (
                          <Typography sx={styles.boxValuesText}>-</Typography>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                      <Typography
                        sx={{
                          ...styles.boxTitle,
                          fontsize: 12,
                          fontWeight: 600,
                          height: 24,
                          py: 0.2,
                        }}>
                        % to
                      </Typography>
                      <Box sx={styles.boxValues}>
                        {feeSlab.hurdlerates?.length ? (
                          feeSlab.hurdlerates?.map((rate: any, rateIndex: number) => (
                            <Typography
                              key={rateIndex}
                              sx={{
                                ...styles.boxValuesText,
                                opacity: rate?.to?.match(numberRegexWithDigit) ? 1 : 0,
                              }}>
                              {rate?.to?.match(numberRegexWithDigit)
                                ? rate.to
                                : `${rate.from} & ${rate.to}`}
                            </Typography>
                          ))
                        ) : (
                          <Typography sx={styles.boxValuesText}>-</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {/* <DataTable
                        tableData={
                          [
                            {
                              ...applicationfeeslab,
                              hurdlerates: applicationfeeslab?.hurdlerates?.length
                                ? applicationfeeslab?.hurdlerates
                                : [],
                            },
                          ] || []
                        }
                        tableHeader={headers
                          .filter((header) => header.isDisplay !== false)
                          .map((header) => {
                            const { isDisplay, ...rest } = header;
                            return rest;
                          })}
                        renderAdditionalRow={false}
                        tableHeaderCustomStyles={{
                          '.MuiTableCell-head': {
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'primary.main',
                            textAlign: 'left',
                            pl: 0,
                            '&:nth-of-type(1)': {
                              pl: 0,
                            },
                            '&:nth-of-type(3)': {
                              pr: 0,
                            },
                            '&:nth-of-type(4)': {
                              pl: 0,
                            },
                            '& .MuiTableRow-root': {
                              '.MuiTableCell-head': {
                                pl: 0,
                                // '&:nth-of-type(3)': {
                                //   pr: 2,
                                // },
                              },
                            },
                          },
                        }}
                        rowCustomStyles={{
                          '.MuiTableCell-root': {
                            fontSize: '1rem',
                            lineHeight: 1.5,
                            py: 0,
                            overflowWrap: 'anywhere',
                            textAlign: 'left',
                            verticalAlign: 'middle',
                            '&:nth-of-type(3)': {
                              pr: 0,
                            },
                            '&:nth-of-type(4)': {
                              pl: 0,
                            },
                            '& .MuiTableRow-root': {
                              '.MuiTableCell-head': {
                                '&:nth-of-type(1)': {
                                  pl: 0,
                                },
                              },
                            },
                          },
                        }}
                        boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                      /> */}
      </Gridstyles>
    </React.Fragment>
  );
};

export default function StrategyDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicationstrategydetails, liquidApplicationFeeSlab, applicationfeeslab } =
    application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const headers = [
    {
      header: 'Fee Name',
      label: 'feeName',
      renderCell: (params: updatedFeeSlabDetails) => {
        return <Typography sx={{ minWidth: '200px' }}>{params.feeName}</Typography>;
      },
    },
    {
      header: 'Management Fees',
      label: 'managementFees',
      renderCell: (params: updatedFeeSlabDetails) => {
        return <>{params.mgmtFee}</>;
      },
    },
    {
      header: 'Operating Expenses',
      label: 'operatingExpenses',
      renderCell: (params: updatedFeeSlabDetails) => {
        return <>{params.optFee}</>;
      },
    },
    {
      header: () => {
        return (
          <>
            <Hidden only="xs">
              <TableHead sx={{ my: 0, py: 0 }}>
                <TableCell
                  align="center"
                  sx={{
                    my: 0,
                    border: 0,
                    py: 0,
                    minWidth: 200,
                  }}>
                  {' '}
                  Profit sharing % above hurdle rate{' '}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    my: 0,
                    border: 0,
                    py: 0,
                  }}>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={2}
                      sx={{
                        my: 0,
                        border: 0,
                        py: 0,
                      }}>
                      <Typography sx={{ textAlign: 'center' }}>Profit Hurdle Rate</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.header}>% from</TableCell>
                    <TableCell sx={styles.header}>% to</TableCell>
                  </TableRow>
                </TableCell>
              </TableHead>
            </Hidden>
            <Hidden smUp={true}>
              <Typography variant="h6">Hurdle Rate</Typography>
            </Hidden>
          </>
        );
      },
      label: 'hurdlerates',
      isDisplay: ['Hybrid', 'Variable', null].includes(applicationfeeslab?.feeType),
      isVisibleInMobileView: (params: updatedFeeSlabDetails) => !!params?.hurdlerates?.length,
      renderEditDeleteCell: (params: updatedFeeSlabDetails) => {
        return params.hurdlerates?.map((rate) => {
          return (
            <>
              <Hidden only="xs">
                <TableRow sx={styles.header}>
                  <TableCell align="center" sx={{ ...styles.header, minWidth: 200, pl: 6 }}>
                    {rate.profit}
                  </TableCell>
                  {rate.from && rate?.to?.match(numberRegex) ? (
                    <TableCell sx={styles.header}>
                      <TableRow>
                        <TableCell align="center" sx={{ ...styles.header, pl: 0 }}>
                          {rate.from}
                        </TableCell>
                        <TableCell align="center" sx={styles.header}>
                          {rate.to}
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  ) : (
                    <TableCell colSpan={2} sx={styles.header}>
                      {`${rate.from} & ${rate.to}`}
                    </TableCell>
                  )}
                </TableRow>
              </Hidden>
              <Hidden smUp={true}>
                <Grid item>
                  <Typography variant="h6">Profit sharing % above hurdle rate</Typography>
                  <Typography>{rate.profit}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Profit Hurdle Rate</Typography>
                  {rate.from && rate?.to?.match(numberRegex) ? (
                    <>
                      <Grid item>
                        <Typography variant="h6">% from</Typography>
                        <Typography>{rate.from}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">% to</Typography>
                        <Typography>{rate.to}</Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Typography>{`${rate.from} & ${rate.to}`}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Hidden>
            </>
          );
        });
      },
    },
    {
      header: () => {
        return (
          <>
            <Hidden only="xs">
              <TableHead sx={{ my: 0, py: 0 }}>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={4}
                    sx={{
                      my: 0,
                      border: 0,
                      py: 0,
                    }}>
                    <Typography sx={{ textAlign: 'center' }}>Exit Load with in days</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* {params.exitload?.map((exit) => {
                    return <TableCell sx={styles.header}>{`${exit.from} to ${exit.to}`}</TableCell>;
                  })} */}
                  <TableCell sx={styles.header}>1 Year</TableCell>
                  <TableCell sx={styles.header}>2 Years</TableCell>
                  <TableCell sx={styles.header}>3 Years</TableCell>
                  <TableCell sx={styles.header}>3 Years & more</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <Hidden smUp={true}>
              <Typography variant="h6">Exit Load with in days</Typography>
            </Hidden>
          </>
        );
      },
      label: 'exitFee',
      renderCell: (params: updatedFeeSlabDetails) => {
        return (
          <>
            <Hidden only="xs">
              <TableCell sx={styles.header}>
                {params.exitload?.map((exit, i) => {
                  return (
                    <TableCell align="center" key={i} sx={styles.header}>
                      {exit.exitload}
                    </TableCell>
                  );
                })}
              </TableCell>
            </Hidden>
            <Hidden smUp={true}>
              {params.exitload?.map((exit, i) => {
                return (
                  <Grid item xs={6} sm={4} key={i}>
                    <Typography variant="h6">{`${exit.frequency}`}</Typography>
                    <Typography>{exit.exitload}</Typography>
                  </Grid>
                );
              })}
            </Hidden>
          </>
        );
      },
    },
    {
      header: '',
      label: 'end',
      renderCell: (params: updatedFeeSlabDetails) => {
        return <>{params.end}</>;
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Strategy Details
            </Typography>
            <React.Fragment>
              <Box sx={{ width: '100%' }}>
                {applicationstrategydetails?.length > 0 && (
                  <Grid
                    item
                    container
                    pb={1}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Total Investment Amount (in INR)'}
                      description={application.investmentAmount}
                      amount={
                        application.investmentAmount
                          ? currencyConversion(application.investmentAmount)
                          : application.investmentAmount
                      }
                    />
                  </Grid>
                )}

                {applicationstrategydetails?.length > 0 ? (
                  applicationstrategydetails.map((applicationstrategydetail, index) => {
                    return (
                      <React.Fragment key={index}>
                        <ApplicationDetailComponent
                          applicationstrategydetail={applicationstrategydetail}
                          liquidApplicationFeeSlab={liquidApplicationFeeSlab}
                          applicationfeeslab={applicationfeeslab}
                        />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Grid
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'common.black',
                      textAlign: 'center',
                      my: 3,
                    }}>
                    No Strategy Details
                  </Grid>
                )}
              </Box>
            </React.Fragment>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
